
import object from './utils/object';
import string from './utils/string';

class RestProvider{
    constructor(opts, $queryProvider){
        opts = opts || {};
        this.host = opts.host || this.constructor.host;
        this.host = this.host || 'localhost';
        this.port = opts.port || this.constructor.port;
        this.port = this.port || 80;
        this.path = opts.path || this.constructor.path;
        this.path = this.path || '/api';
        this.protocol = opts.protocol || this.constructor.protocol;
        this.protocol = this.protocol || 'http';
        this.withCredentials = opts.withCredentials || this.constructor.withCredentials;
        this.excludeResourceFromPath = opts.excludeResourceFromPath || this.constructor.excludeResourceFromPath;
        if ($queryProvider !== undefined) this.$queryProvider = $queryProvider;
    }

    get(params, resource, query, callback){
        if(this.excludeResourceFromPath) resource=null;
        if (typeof query === 'function') callback = query;

        var options = this._getOptions(resource, 'GET', undefined);

        var q = '';
        var i = 0;
        var _VAL=null;
        var _LENGTH=0;
        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                _LENGTH+=1;
                var val = encodeURIComponent(params[key]);
                _VAL=val;
                if (i === 0) {
                    q += '?' + key + '=' + val;
                    i++;
                } else {
                    q += '&' + key + '=' + val;
                }
            }
        }

        //if params, length > 1, append key,values as queries to path endpoint
        if (_LENGTH>1) {
          if(resource) options.path += (resource.indexOf('/') === -1) ? '/' + q : q;
          else options.path +=q;
        }else if(_LENGTH===1){ //else, length==1, append value to path
          if(resource) options.path += (resource.indexOf('/') === -1) ? '/' + _VAL : _VAL;
          else options.path +=_VAL;
        }

        //test query options
        if (query && typeof query.filter !== 'undefined' && !object.isEmpty(query.filter)) {
            options.path += this.$queryProvider.filter(options.path, query.filter);
        }

        if (query && typeof query.orderBy !== 'undefined' && !object.isEmpty(query.orderBy)) {
            options.path += this.$queryProvider.orderBy(options.path, query.orderBy);
        }

        if (query && typeof query.orderByDesc !== 'undefined' && !object.isEmpty(query.orderByDesc)) {
            options.path += this.$queryProvider.orderByDesc(options.path, query.orderBy, query.orderByDesc);
        }

        if (query && typeof query.paginate !== 'undefined') {
            options.path += this.$queryProvider.paginate(options.path, query.paginate);
        } else {
            //don't allow mixing of paginate with skip/top since paginate is more or less a convenience wrapper for skip & top
            if (query && typeof query.skip !== 'undefined') options.path += this.$queryProvider.skip(options.path, query.skip);
            if (query && typeof query.top !== 'undefined') options.path += this.$queryProvider.top(options.path, query.top);
        }

        this._pipe(options, callback);
    }

    post(params, resource, callback) {
        if(this.excludeResourceFromPath) resource=null;
        var options = this._getOptions(resource, 'POST', params);
        this._pipe(options, callback);
    }

    put(params, resource, callback) {
        if(this.excludeResourceFromPath) resource=null;
        var options = this._getOptions(resource, 'PUT', params);
        this._pipe(options, callback);
    }

    delete(params, resource, callback) {
       if(this.excludeResourceFromPath) resource=null;
       var options = this._getOptions(resource, 'DELETE', undefined);
       var q = '';
       var i = 0;
       var _VAL=null;
       var _LENGTH=0;
       for (var key in params) {
         if (params.hasOwnProperty(key)) {
           var val = encodeURIComponent(params[key]);
           _LENGTH+=1;
           _VAL=val;
           if (i === 0) {
             q += '?' + key + '=' + val;
             i++;
           } else {
             q += '&' + key + '=' + val;
           }
         }
       }

       if (_LENGTH>1) {
         if(resource) options.path += (resource.indexOf('/') === -1) ? '/' + q : q;
         else options.path +=q;
       }else if(_LENGTH===1){ //else, length==1, append value to path
         if(resource) options.path += (resource.indexOf('/') === -1) ? '/' + _VAL : _VAL;
         else options.path +=_VAL;
       }

       //send
       this._pipe(options, callback);
    }

    _pipe(options, resource, callback) {

        /* we asynchronously pass through to onSend(if a callback has been defined)
         ex:
         $myRestProvider.onSend=function(options, callback){
         options.authorization=http.encodeSessionToken(req.cookies.authToken);
         callback.call(this,null,options);
         };
         */

        if(this.onSend) {
            this.onSend(options,(err,data)=>{
                if(err) callback(err,data);
                else this._send(data,callback);
            });
        }
        else this._send(options,callback);
    }

    _send(params,callback){
        var boolSendData=true;
        var method=params.method || 'get';
        method=method.toLowerCase();
        var value=null;
        if (params.data) {
            value = JSON.stringify(params.data);
            boolSendData=true;
        } 
        var url=params.protocol + '://' + params.host + ':' + (params.port || 80) + params.path
        var req= new XMLHttpRequest();
        req.open(method,encodeURI(url));
        req.setRequestHeader('Content-Type', 'application/json');
        if (params.authorization) req.setRequestHeader('Authorization', params.authorization);
        if(params.headers){
            for (var key in params) {
               if (params.hasOwnProperty(key)) req.setRequestHeader(key, params[key]);
            }
        }
        req.onload = function() {
            if (req.status === 200) {
                let data=JSON.parse(req.response);
                callback(null,data);
            }
            else{
                let statusText=req.statusText;
                callback({statusCode:req.status,message:req.statusText},null);
            }
        };
        if(boolSendData) req.send(value);
        else req.send();
    }


    _getOptions(resource, method, data) {
        var options = {};
        options.host = this.host || this.constructor.host;
        options.port = this.port || this.constructor.port;
        options.method = method;
        options.path = this.path || this.constructor.path;
        if(resource) resource = (string.firstChar(resource) === '/') ? resource : '/' + resource;
        else resource='';
        options.path = options.path + resource;
        options.protocol = this.protocol || this.constructor.protocol;
        options.withCredentials = this.withCredentials || this.constructor.withCredentials;
        if (data && data !== undefined) options.data = data;
        return options;
    }


}

RestProvider.protocol = null;
RestProvider.host = null;
RestProvider.path = null;
RestProvider.port = null;
RestProvider._data= null;
RestProvider.$queryProvider=null;
RestProvider.withCredentials=false;
RestProvider.excludeResourceFromPath=false;

export default RestProvider;